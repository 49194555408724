const images = require.context("../assets/projects/StayKSU/images", false, /.*\.(png|jpe?g|svg)$/);
const thumbnailImages = require.context("../assets/projects/StayKSU/thumbnails", false, /.*\.(png|jpe?g|svg)$/);
const importedImages = images.keys().map(images);
const importedThumbnailImages = thumbnailImages.keys().map(thumbnailImages);

export const STAYKSU_ar = {
    id: "STAYKSU",
    name: "ستاي جامعة الملك سعود",
    residential_Unit_Type: "شقق",
    scope: "تطوير",
    status: "قيد الموافقة",
    starting_price: null,
    starting_price_text: null,
    number_of_bedrooms: null,
    location: "جامعة الملك سعود",
    area: "123,550 m²",
    rotate_images: false,
    stats: {
        units: {
            value: "+300",
            unit: "",
        },
        total_built_up_area: {
            value: "20,000",
            unit: "م²",
        }
    },
    nearby_description: "يقع الموقع داخل حرم جامعة الملك سعود.",
    team: [],
    main_images: [
        importedImages[0]
    ],
    text_sections: [
        "ستاي هي شركة في مجال تطوير العقارات السكنية وتأجيرها، منذ عام ٢٠١٥ وتعمل على توفير مجمعات سكنية متكاملة بمرافق وخدمات استثنائية واختيارات متنوعة من الشقق المؤثثة ونصف المفروشة بفترة تأجير مناسبة.",
        "ستاي في جامعة الملك سعود هي مشروع ريادي، يقدم تجربة معيشية مميزة تلبي احتياجات السكن العصرية بتصميم أنيق. يقع المشروع داخل أكبر حرم جامعي في السعودية، وعلى مقربة من أكبر مقرات الشركات ومراكز الأعمال في الرياض، مما يوفر للسكان سهولة الوصول إلى معالم رئيسية. يركز التطوير على توفير مساحات عملية وجمالية تكمل احتياجات المنزل العصري، مع مراعاة معايير الاستدامة.",
        "أول تطوير متعدد الاستخدامات لشركة ستاي، بحيث يجمع المشروع بين السكني والتجاري. يوفر التطوير أكثر من 300 وحدة مع استغلال كامل للمساحات، لزيادة راحة السكان ورفاهيتهم. تتنوع الوحدات من الشقق, لوفت, و تاون هومز.",
    ],
    section_images: {},
    plans_cover: null,
    plnas_description: "The plans efficiently utilize space, offering clearly defined areas for living, dining, cooking, and relaxation. Each space is designed to be adaptable and multifunctional, catering to diverse needs and activities.",
    location_dev: {
        latitude: 24.735128,
        longitude: 46.623833,
        google_maps_url: "https://maps.app.goo.gl/o5rCDCaJFSAdHHqg8",
    },
    images: importedImages.map((image, index) => {
        return {
            image: image,
            description: "",
            thumbnail: importedThumbnailImages[index],
        }
    }, []),
    hasPlansOverlay: false,
    hasPlans: false,
    hasLocation: true,
    forSale: false,
    plans: {},
    villaInof: {},
    floorPlans: [],
};