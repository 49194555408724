import React from 'react';
import { useTranslation } from 'react-i18next';
import './Stat.css';

const Stat = ({ stat, value, unit = "", prefix = "" }) => {
    const { t } = useTranslation();
    return (
        <div className='stat'>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <p style={{ marginBottom: 0 }}>{prefix}</p>&nbsp;
                {Array.isArray(value) ? value.map((val, index) => {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'baseline' }}>
                            <h1>{val}</h1>
                            {index === value.length - 1 ? null : <>&nbsp;<p style={{ marginBottom: 0 }}>{t('common.or')}</p>&nbsp;</>}
                        </div>
                    );
                }) : <h1>{value}</h1>}
                &nbsp;<p style={{ marginBottom: 0 }}>{unit}</p>
            </div>
            <p className='stat-string'>{stat}</p>
        </div >
    );
};

export default Stat;
