import FirstFloor from "../assets/projects/StayAlNakheel/floorplans/1.png";
import SecondFloor from "../assets/projects/StayAlNakheel/floorplans/2.png";

const images = require.context("../assets/projects/StayAlNakheel/images", false, /.*\.(png|jpe?g|svg)$/);
const thumbnailImages = require.context("../assets/projects/StayAlNakheel/thumbnails", false, /.*\.(png|jpe?g|svg)$/);
const importedImages = images.keys().map(images);
const importedThumbnailImages = thumbnailImages.keys().map(thumbnailImages);

export const StayAlNakheel = {
    id: "StayAlNakheel",
    name: "Stay AlNakheel",
    residential_Unit_Type: "Apartments",
    scope: "Develop",
    status: "Completed",
    starting_price: null,
    starting_price_text: null,
    number_of_bedrooms: null,
    location: "AlNakheel",
    area: "5,500 m²",
    stats: {
        units: {
            value: 33,
            unit: "",
        },
        floors_in_unit: {
            value: 1,
            unit: "",
        },
        built_up_area_per_unit: {
            prefix: "Up to",
            value: 110,
            unit: "m²",
        },
        total_built_up_area: {
            value: "5,500",
            unit: "m²",
        }
    },
    nearby_description: "Situated conveniently at the junction of Northern Ring Road and Prince Turki al Awwal Road, it offers seamless vehicular connections. The property rests within a 600-meter radius of King Saud's University, a 900-meter radius of Riyadh's Digital City, and just 2 and 3 kilometers from King Abdullah Financial District and Diriyah Gate Authority respectively. This central positioning grants residents and visitors immediate access to major business hubs, and academic institutions.",
    team: [
        "Abdulaziz Alkassim",
        "Deema Alkassim",
        "Abdulrahman Alshawbaki",
    ],
    main_images: [
        importedImages[6],
        importedImages[2],
        importedImages[3],
    ],
    text_sections: [
        "STAY is a Build to Rent Residential real estate development & multifamily property management company providing communities with exceptional facilities & amenities along numerous semi-furnished & furnished floor plans offering various lease periods that determine where you want to STAY since 2015.",
        "STAY at alNakheel a visionary project, where it offers a distinctive living experience addressing the current housing needs with modern and sleek design. The project is situated in close proximity with the largest business headquarters, and centers in Riyadh, offering residents easy access to prime landmarks. The development focuses on providing residents with a functional, aesthetic space complementing the needs of a modern home, with sustainability features.",
        "The project’s design stands out with a unique double façade, that is distinct to the surrounding architecture, reflecting the STAY’s approach to sustainability standards in design. Inside the building, the residents are welcomed by a landscaped courtyard acting as a buffer to separate units, creating a luminous and inviting atmosphere with distinct view from all the three stories of the building. The development offers 33 units with maximum optimization of spaces, for increased resident comfort and well-being. The apartments range from one, and two bedrooms, featuring a comfortable living area, dining area, kitchen, and washrooms. For entertainment and relaxation, the project features amenities such as a rooftop pool, outdoor barbeque and seating areas, and a recreational clubhouse. And the rooftop offers panoramic views to the surrounding area, and bustling neighborhood.",
    ],
    section_images: {},
    plans_cover: FirstFloor,
    plnas_description: "The plans efficiently utilize space, offering clearly defined areas for living, dining, cooking, and relaxation. Each space is designed to be adaptable and multifunctional, catering to diverse needs and activities.",
    location_dev: {
        latitude: 24.74425,
        longitude: 46.6135,
        google_maps_url: "https://maps.app.goo.gl/PjMfVLnzvYk8s5jT7",
    },
    images: importedImages.map((image, index) => {
        return {
            image: image,
            description: "",
            thumbnail: importedThumbnailImages[index],
        }
    }, []),
    hasPlansOverlay: false,
    hasPlans: true,
    hasLocation: true,
    forSale: false,
    plans: {},
    villaInof: {},
    globalDescriptionList: [
        "1 Living Area",
        "2 Dining Area",
        "3 Kitchen",
        "4 W.C",
        "5 Laundry",
        "6 Bedroom",
    ],
    floorPlans: [
        {
            name: "First Floor",
            img: FirstFloor,
            area: "",
            descriptionList: [],
        },
        {
            name: "Second Floor",
            img: SecondFloor,
            area: "",
            descriptionList: [],
        },
    ],
};