import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

import './ArrowLink.css';
const ArrowLink = ({ href, text, className, onClick, dir = 'right', disabled = false }) => {
    return (
        <div
            onClick={disabled ? null : onClick}
        >
            {href && href !== "" ? (
                <Link to={href} className={`link_with_arrow section-link ${dir} ${className}`}>
                    {dir === 'left' ? (
                        <>
                            <FaArrowRight /> {text}
                        </>
                    ) : (
                        <>
                            {text}  <FaArrowRight />
                        </>
                    )}
                </Link>
            ) : (
                <div className={`link_with_arrow section-link ${dir} ${className}`}>
                    {dir === 'left' ? (
                        <>
                            <FaArrowRight /> {text}
                        </>
                    ) : (
                        <>
                            {text}  <FaArrowRight />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default ArrowLink;
