import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import ArrowLink from '../ArrowLink/ArrowLink';
import { Row, Col } from 'react-bootstrap';
import { APP_ROUTES } from '../../const/const';
import DoorButton from '../DoorButton/DoorButton';
import NotFound from '../NotFoundPage/NotFound';

import { useLocation } from 'react-router-dom';
import { Projects } from '../../Projects/Main';
import { Projects_ar } from "../../Projects/Main";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import './Gallery.css';

const Gallery = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const queryParams = new URLSearchParams(location.search);
    const project_id = queryParams.get("project_id");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let ProjectList = Projects;
    if (i18n.language === 'ar') {
        ProjectList = Projects_ar;
    }

    const project = ProjectList.find(project => project.id === project_id);
    if (!project) {
        return (
            <NotFound />
        );
    }
    return (
        <>
            <Navbar />
            <Row className={'gallery-header'}>
                <Col xs={3} md={3} lg={3} className={'gallery-header-col d-none d-sm-none d-md-none d-lg-block'}>
                    <ArrowLink text={t('project_details.back')}
                        dir='left'
                        className={'project-details-view-more'} href={APP_ROUTES.PROJECT_DETAILS + '?project_id=' + project.id} />
                </Col>
                <Col xs={12} md={12} lg={6} className={'gallery-header-col'}>
                    <h3>{project.name}</h3>
                </Col>
                <Col xs={12} md={12} lg={3} className={'gallery-header-col'}>
                    {project.forSale && (
                        <DoorButton
                            className={'book-a-tour-button'}
                            text={t('project_details.book_a_tour')} href={APP_ROUTES.BOOK_A_TOUR + '?project_id=' + project.id} />
                    )}
                </Col>
            </Row>

            <Row className={'gallery-body'}>
                <Col xs={12} md={12} lg={12} className={'gallery-body-col'}>
                    <ImageGallery
                        className={'gallery'}
                        originalHeight={'100%'}
                        originalWidth={'100%'}
                        originalClass={'gallery-image'}
                        items={project.images.map((image, index) => {
                            return {
                                original: image.image,
                                thumbnail: image.thumbnail,
                                description: image.description,
                            }
                        })} />


                </Col>
            </Row>
            <Footer />
        </>
    );
};

export default Gallery;
