import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './DoorButton.css';

const DoorButton = ({ text, dir = 'left', className, href }) => {
    return (
        <div className={`door-button ${dir} ${className}`}>
            <Link to={href} className='door-link' style={{ display: 'flex', alignItems: 'center' }}>
                <div className='button-text'><div className='button-text-inner'>{text}</div></div>  <FaArrowRight />
            </Link>
        </div>
    );
};

export default DoorButton;
