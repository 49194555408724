import React, { useEffect } from 'react';
import { Fade, Reveal } from 'react-awesome-reveal';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { useTranslation } from 'react-i18next';
import './Projects.css';
import RowProjects from '../RowProjects/RowProjects';
import i18n from '../../i18n';
import { Projects } from '../../Projects/Main';
import { Projects_ar } from "../../Projects/Main";

import TitleHeader from '../TitleHeader/TitleHeader';
import SearchMenu from '../SearchMenu/SearchMenu';


function ProjectsPage() {
  const { t } = useTranslation();
  const [searchedProjects, setSearchedProjects] = React.useState([]);
  let ProjectsList = Projects;
  if (i18n.language === "ar") {
    ProjectsList = Projects_ar;
  }

  const onSearch = (value, type, setOfProjects) => {
    if (type === 'residential_unit_type') {
      if (value === 'all') {
        setSearchedProjects(setOfProjects);
      } else {
        setSearchedProjects(setOfProjects.filter(project => project.residential_Unit_Type === value));
      }
    } else if (type === 'searchString') {
      setSearchedProjects(setOfProjects.filter(
        project => (project.name.toLowerCase().includes(value.toLowerCase()) ||
          (project.location && project.location.toLowerCase().includes(value.toLowerCase())))
      ));
    } else if (type === 'scope') {
      if (value === 'all') {
        setSearchedProjects(setOfProjects);
      } else {
        setSearchedProjects(setOfProjects.filter(project => project.scope === value));
      }
    } else if (type === 'status') {
      if (value === 'all') {
        setSearchedProjects(setOfProjects);
      } else {
        setSearchedProjects(setOfProjects.filter(project => project.status === value));
      }
    } else if (type === 'number_of_bedrooms') {
      if (value === 'all') {
        setSearchedProjects(setOfProjects);
      } else {
        setSearchedProjects(setOfProjects.filter(project => project.number_of_bedrooms && project.number_of_bedrooms >= value[0] && project.number_of_bedrooms <= value[1]));
      }

    } else if (type === 'price_range') {
      if (value === 'all') {
        setSearchedProjects(setOfProjects);
      } else {
        setSearchedProjects(setOfProjects.filter(project => project.starting_price && project.starting_price >= value[0] && project.starting_price <= value[1]));
      }

    } else {
      setSearchedProjects(setOfProjects);
    }
  }

  useEffect(() => {
    setSearchedProjects(ProjectsList);
  }, [ProjectsList]);

  return (
    <div className="scrolling-content">
      <Navbar />
      <TitleHeader title={t('navbar.projects')} />
      <SearchMenu onSearch={onSearch} />
      {searchedProjects.map((project, index) => (
        index % 2 === 0 && (
          <Fade>
            <div className="projects-section">

              <div className='separator' />
              <RowProjects project1={project} project2={searchedProjects[index + 1]} />

            </div>
          </Fade>
        )
      ))}
      <div className='separator' />

      <Footer />
    </div>
  );
}

export default ProjectsPage