import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { useTranslation } from 'react-i18next';
import './Services.css';
import { Row, Col } from 'react-bootstrap';
import StickyBox from 'react-sticky-box';
import { Fade } from 'react-awesome-reveal';

import ourServicesImage from '../../assets/ourServices.jpg'

const Services = () => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(null);

    const updateStickyPosition = () => {
        const stickyContainer = document.getElementsByClassName('sticky-box')[0];
        const windowHeight = window.innerHeight;
        const containerHeight = stickyContainer.offsetHeight;

        stickyContainer.style.top = Math.max((windowHeight - containerHeight) / 2, 0) + 'px';
    };

    const handleScroll = () => {
        updateStickyPosition();
        const centerY = window.innerHeight / 2;
        // dummy elemnt on top, has its index considered
        const serviceElements = document.querySelectorAll('.service');
        const newActiveIndex = Array.from(serviceElements).findIndex((element) => {
            const rect = element.getBoundingClientRect();
            return rect.top < centerY && rect.bottom > centerY;

        });

        setActiveIndex(newActiveIndex);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />
            <div className="content">
                <div className="services-section service-text-section">
                    <Fade>
                        <h3 className="title"> {t('services.title')} </h3>
                        <p> {t('services.text')} </p>
                    </Fade>
                </div>

                <div className="services-section">
                    <Row>
                        <Col xs={12} md={12} lg={6} className='no-padding-mobile'>
                            <StickyBox className='sticky-box'>
                                <Fade>
                                    < div className="service-img-container">
                                        <img src={ourServicesImage} alt="our services" className="our-services-image" />
                                    </div>
                                </Fade>
                            </StickyBox>
                        </Col>

                        <Col xs={12} md={12} lg={6}>
                            <div className="service dummy" />
                            {[1, 2, 3, 4].map((index) => (
                                <div
                                    className={`service padding-mobile ${activeIndex === index ? 'active' : ''}`}
                                    key={index}
                                >
                                    <h4 className="service-title"> {t(`services.service${index}.title`)} </h4>
                                    <p className="service-text"> {t(`services.service${index}.text`)} </p>
                                </div>
                            ))}
                            {/* Add dummy service elements to the bottom of the page to make the last service element active */}
                            <div className="service dummy" />
                            <div className="service dummy" />
                            <div className="service dummy only-tablet" />
                        </Col>
                    </Row>
                </div>
            </div >
            <Footer />
        </>
    );
};

export default Services;
