
import './ContactUs.css';
import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import ArrowLink from '../ArrowLink/ArrowLink';
import { GOOGLE_MAPS_API_KEY } from '../../const/const';
import { Alert, Spinner } from 'react-bootstrap';

import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import ReCAPTCHA from 'react-google-recaptcha';

import stayStyle from '../../assets/stayStyle.json';
import mapsIcon from '../../assets/maps-icon.png';


const ContactUs = () => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [msg, setMsg] = useState(null);
    const [msgType, setMsgType] = useState('danger');
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [formState, setFormState] = useState({
        inquiry: 'general',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        description: '',
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value });
    };

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        if (!recaptchaValue) {
            setMsg(t('common.recaptcha_error'));
            setMsgType('danger');
            setLoading(false);
            return;
        }

        const jsonBody = {
            inqueryType: formState.inquiry,
            firstName: formState.firstName,
            lastName: formState.lastName,
            email: formState.email,
            phone: formState.phone,
            description: formState.description,
        }

        try {
            const response = await fetch('contact.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': i18n.language,
                },
                body: JSON.stringify({ ...jsonBody, 'g-recaptcha-response': recaptchaValue }),
            });

            const result = await response.json();

            if (result.status === 'error') {
                setMsgType('danger');
                setMsg(result.msg);
            } else {
                setSubmitSuccess(true);
                setMsgType('success');
                setMsg(t('common.success'));
                setFormState({
                    inquiry: 'general',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    description: '',
                });
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
            setMsgType('danger');
            setMsg(t('common.server_error'));
        } finally {
            setLoading(false);
        }
    }


    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    });

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };


    const mapContainerStyle = {
        width: '100%',
        height: '400px',
        marginBottom: '100px',
    };
    const center = {
        lat: 24.711201,
        lng: 46.631518,
    };

    const drfahMarker = {
        position: center,
        visible: true,
        title: 'Drfah',
        icon: mapsIcon,
        animation: 2,
        onClick: () => {
            window.open('https://maps.app.goo.gl/yJhz76GQaUdkCTSh6', '_blank');
        }
    };

    return (
        <>
            <Navbar />
            <Row className='contact-us-section'>
                <Col xs={12} md={12} lg={5}>
                    <h3>
                        {t('contact_us.title')}
                    </h3>
                </Col>
                <Col xs={12} md={12} lg={7}>

                    <Form onSubmit={handleSubmit} style={{ position: 'relative' }}>
                        <div className="success-message">
                            {submitSuccess && (
                                <h3>
                                    {t('common.success')}
                                </h3>
                            )}
                        </div>
                        <div style={{ opacity: submitSuccess ? 0 : 1 }} className={submitSuccess ? 'contact-us-form-none' : ''}>
                            <Form.Group controlId="inquirySelect">
                                <Form.Label>{t('contact_us.select_inquiry')}</Form.Label>
                                <Form.Control as="select" value={formState.inquiry} name="inquiry" onChange={handleInputChange}>
                                    <option value="general">{t('contact_us.inquiry_options.general')}</option>
                                    <option value="support">{t('contact_us.inquiry_options.support')}</option>
                                    <option value="sales">{t('contact_us.inquiry_options.sales')}</option>
                                </Form.Control>
                            </Form.Group>

                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="firstName">
                                        <Form.Label>{t('contact_us.first_name')}</Form.Label>
                                        <Form.Control type="text" placeholder={t('contact_us.first_name')} value={formState.firstName} name="firstName" onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="lastName">
                                        <Form.Label>{t('contact_us.last_name')}</Form.Label>
                                        <Form.Control type="text" placeholder={t('contact_us.last_name')} value={formState.lastName} name="lastName" onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="email">
                                        <Form.Label>{t('contact_us.email')}</Form.Label>
                                        <Form.Control type="email" placeholder={t('contact_us.email')} value={formState.email} name="email" onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="phone">
                                        <Form.Label>{t('contact_us.phone')}</Form.Label>
                                        <Form.Control type="text" placeholder={t('contact_us.phone')} value={formState.phone} name="phone" onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group controlId="description">
                                <Form.Label>{t('contact_us.description')}</Form.Label>
                                <Form.Control as="textarea" rows={3} value={formState.description} name="description" onChange={handleInputChange} />
                            </Form.Group>
                            <Form.Group controlId="recaptcha" className="mt-3">
                                <ReCAPTCHA
                                    sitekey="6LfuedQkAAAAADr9jcezg632iLfHa4KJUf8vtQS2"
                                    onChange={handleRecaptchaChange}
                                />
                            </Form.Group>
                            <br />
                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <ArrowLink href='' text={t('contact_us.submit_request')} onClick={handleSubmit} disabled={loading} className={loading ? 'disabled' : ''} />
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    {loading == true && <Spinner animation="border" variant="dark" size='sm' className={i18n.language === 'ar' ? 'float-left' : 'float-right'} />}
                                </Col>
                            </Row>

                            {msg && <Alert variant={msgType} className="mt-3">
                                <div dangerouslySetInnerHTML={{ __html: msg }} />
                            </Alert>
                            }
                        </div>
                    </Form>

                </Col>
            </Row>
            <Row className='contact-us-section'>
                <Col xs={12} md={12} lg={5} className='order-last order-lg-first'>
                    <h3>
                        {t('contact_us.our_office')}
                    </h3>
                    <p>
                        {t('contact_us.office_address')}
                    </p>
                    <br />
                    <p>
                        {t('contact_us.email')}: <a className='nav-link-no-arrow email-link'
                            href={`mailto:${t("contact_us.drfah_email")}`}>{t('contact_us.drfah_email')}</a>
                    </p>
                </Col>
                <Col xs={12} md={12} lg={7} className='order-first order-lg-las map-container'>
                    {isLoaded && (
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            zoom={17}
                            center={center}
                            options={{
                                styles: stayStyle,
                                fullscreenControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                            }}

                        >
                            <MarkerF {...drfahMarker} />
                        </GoogleMap>
                    )}
                </Col>
            </Row>
            <Footer />
        </>
    );
};

export default ContactUs;
