import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

import { Projects } from '../../Projects/Main';
import { Projects_ar } from "../../Projects/Main";

import './SearchMenu.css';

function SearchMenu({ onSearch }) {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const { t, i18n } = useTranslation();
    const [selectedFilter, setSelectedFilter] = React.useState('')
    const [searchString, setSearchString] = React.useState('')
    const [selectedType, setSelectedType] = React.useState('')
    const [matchedProjects, setMatchedProjects] = React.useState([])
    const handleToggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };
    let ProjectsList = Projects;
    if (i18n.language === "ar") {
        ProjectsList = Projects_ar;
    }


    const ProjectsWithPrice = ProjectsList.filter(project => project.starting_price !== null);
    const ProjectsWithBedrooms = ProjectsList.filter(project => project.number_of_bedrooms !== null);
    const ProjectsWithPriceAndBedrooms = ProjectsList.filter(project => project.starting_price !== null && project.number_of_bedrooms !== null);
    const [serachkeys, setSerachkeys] = React.useState({
        propertiesTypes: Array.from(new Set(ProjectsList.map(project => project.residential_Unit_Type))),
        scope: Array.from(new Set(ProjectsList.map(project => project.scope))),
        status: Array.from(new Set(ProjectsList.map(project => project.status))),
        price: {
            lowest: ProjectsWithPrice.reduce((min, project) => project.starting_price < min ? project.starting_price : min, +Infinity),
            highest: ProjectsWithPrice.reduce((max, project) => project.starting_price > max ? project.starting_price : max, -Infinity)
        },
        number_of_bedrooms: {
            lowest: ProjectsWithBedrooms.reduce((min, project) => project.number_of_bedrooms < min ? project.number_of_bedrooms : min, +Infinity),
            highest: ProjectsWithBedrooms.reduce((max, project) => project.number_of_bedrooms > max ? project.number_of_bedrooms : max, -Infinity)
        }
    })

    useEffect(() => {
        if (selectedType === 'portfolio') {
            setMatchedProjects(ProjectsList.filter(project => project.forSale === false))
        } else if (selectedType === 'openForSale') {
            setMatchedProjects(ProjectsList.filter(project => project.forSale === true))
        } else {
            setMatchedProjects(ProjectsList)
        }
    }, [selectedType])

    const UpdateSearchingKeys = () => {
        onSearch('', '', matchedProjects)
        if (selectedType === 'portfolio') {
            setSerachkeys({
                propertiesTypes: Array.from(new Set(matchedProjects.map(project => project.residential_Unit_Type))),
                scope: Array.from(new Set(matchedProjects.map(project => project.scope))),
                status: Array.from(new Set(matchedProjects.map(project => project.status))),
                price: {
                    lowest: 0,
                    highest: 0
                },
                number_of_bedrooms: {
                    lowest: 0,
                    highest: 0
                }
            })
        } else if (selectedType === 'openForSale') {
            setSerachkeys({
                propertiesTypes: Array.from(new Set(matchedProjects.map(project => project.residential_Unit_Type))),
                scope: Array.from(new Set(matchedProjects.map(project => project.scope))),
                status: Array.from(new Set(matchedProjects.map(project => project.status))),
                price: {
                    lowest: ProjectsWithPrice.reduce((min, project) => project.starting_price < min ? project.starting_price : min, +Infinity),
                    highest: ProjectsWithPrice.reduce((max, project) => project.starting_price > max ? project.starting_price : max, -Infinity)
                },
                number_of_bedrooms: {
                    lowest: ProjectsWithPriceAndBedrooms.reduce((min, project) => project.number_of_bedrooms < min ? project.number_of_bedrooms : min, +Infinity),
                    highest: ProjectsWithPriceAndBedrooms.reduce((max, project) => project.number_of_bedrooms > max ? project.number_of_bedrooms : max, -Infinity)
                }
            })
        } else {
            setSerachkeys({
                propertiesTypes: Array.from(new Set(ProjectsList.map(project => project.residential_Unit_Type))),
                scope: Array.from(new Set(ProjectsList.map(project => project.scope))),
                status: Array.from(new Set(ProjectsList.map(project => project.status))),
                price: {
                    lowest: ProjectsWithPrice.reduce((min, project) => project.starting_price < min ? project.starting_price : min, +Infinity),
                    highest: ProjectsWithPrice.reduce((max, project) => project.starting_price > max ? project.starting_price : max, -Infinity)
                },
                number_of_bedrooms: {
                    lowest: ProjectsWithBedrooms.reduce((min, project) => project.number_of_bedrooms < min ? project.number_of_bedrooms : min, +Infinity),
                    highest: ProjectsWithBedrooms.reduce((max, project) => project.number_of_bedrooms > max ? project.number_of_bedrooms : max, -Infinity)
                }
            })
        }
    }

    useEffect(() => {
        setSelectedType('')
    }, [i18n.language])

    useEffect(() => {
        UpdateSearchingKeys()
    }, [matchedProjects])

    const [selectedPrice, setSelectedPrice] = React.useState([serachkeys.price.lowest, serachkeys.price.highest])
    const [selectedBedrooms, setSelectedBedrooms] = React.useState([serachkeys.number_of_bedrooms.lowest, serachkeys.number_of_bedrooms.highest])

    const isMobile = window.innerWidth < 992;

    useEffect(() => {
        setSelectedBedrooms([serachkeys.number_of_bedrooms.lowest, serachkeys.number_of_bedrooms.highest])
        setSelectedPrice([serachkeys.price.lowest, serachkeys.price.highest])
    }, [serachkeys])

    useEffect(() => {
        UpdateSearchingKeys()
    }, [i18n.language])
    return (
        <div className={`search-menu-container ${isExpanded ? 'expanded' : 'shrink'}`}>
            <Row className={`search-menu-row ${isExpanded ? 'expanded' : ''}`}>
                <Col md={11} xs={11}>
                    <div className={`search-menu ${isExpanded ? 'search-menu-hidden' : ''}`}>
                        <a href='#' className='nav-link-no-arrow firstItem' onClick={() => onSearch("all", 'residential_unit_type', matchedProjects)}>
                            {t('common.all')}
                        </a>
                        {serachkeys.propertiesTypes.slice(0, 3).map((propertyType) => (
                            <a href='#' className='nav-link-no-arrow inactive' onClick={() => onSearch(propertyType, 'residential_unit_type', ProjectsList)}>
                                {propertyType}
                            </a>
                        ))}
                    </div>
                </Col>
                <Col xs={1} md={1} className='d-flex justify-content-end'>
                    <div className={`search-menu-icon ${isExpanded ? 'search-icon-close' : ''}`}>
                        <FontAwesomeIcon
                            icon={isExpanded ? faTimes : faSearch}
                            className='search-icon'
                            size='lg'
                            onClick={handleToggleExpansion}
                        />
                    </div>
                </Col>
            </Row>
            <div className={`hidden-search ${isExpanded ? 'expanded' : 'shrink'}`}>
                <Row style={{ display: 'flex' }}>
                    <Col md={12} style={{ position: 'relative', marginTop: '15px' }}>
                        <input type='text' className='search-input' placeholder={t('searchbar.searchString')} value={searchString}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch(searchString, 'searchString', matchedProjects)
                                    setIsExpanded(false)
                                }
                            }}
                            onChange={(e) => setSearchString(e.target.value)} />
                        <a href="#" className='search-link nav-link-no-arrow'
                            onClick={() => {
                                onSearch(searchString, 'searchString', matchedProjects)
                                setIsExpanded(false)
                            }}
                        >{t('searchbar.search')}
                        </a>
                        <FontAwesomeIcon icon={faSearch} className='search-link-icon' onClick={() => {
                            onSearch(searchString, 'searchString', matchedProjects)
                            setIsExpanded(false)
                        }} />
                    </Col>
                </Row>
                <Row className='options-row'>
                    <Col xs={12} md={2}>
                        {isMobile ? (
                            <Row>
                                <Col xs={4} md={4}>
                                    <a href='#' className={`nav-link-no-arrow search-menu-title`}>
                                        {t('searchbar.projectType')}:
                                    </a>
                                </Col>
                                <Col xs={8} md={8}>
                                    {selectedType === 'portfolio' ? (
                                        <>
                                            <span className="badge bg-dark"
                                                onClick={() => setSelectedType('')}
                                            >{t('searchbar.portfolio')}</span>
                                        </>
                                    ) : <a href='#' className='nav-link-no-arrow search-menu-item' onClick={() => setSelectedType('portfolio')}>{t('searchbar.portfolio')}</a>
                                    }
                                    {selectedType === 'openForSale' ? (
                                        <>
                                            <span className="badge bg-dark"
                                                onClick={() => setSelectedType('')}
                                            >{t('searchbar.openForSale')}</span>
                                        </>

                                    ) : <a href='#' className='nav-link-no-arrow search-menu-item' onClick={() => setSelectedType('openForSale')}>{t('searchbar.openForSale')}</a>
                                    }
                                </Col>


                            </Row>

                        ) : (
                            <h6 className='search-menu-title'>{t('searchbar.projectType')}</h6>
                        )}
                        <div className='search-menu-options'>
                            <div className='search-menu-item-container'>
                                {selectedType === 'portfolio' ? (
                                    <>
                                        <span className="badge bg-dark"
                                            onClick={() => setSelectedType('')}
                                        >{t('searchbar.portfolio')}</span>
                                    </>
                                ) :
                                    <a href='#' className='nav-link-no-arrow search-menu-item' onClick={() => setSelectedType('portfolio')}>{t('searchbar.portfolio')}</a>
                                }
                            </div>

                            <div className='search-menu-item-container'>
                                {selectedType === 'openForSale' ? (
                                    <>
                                        <span className="badge bg-dark"
                                            onClick={() => setSelectedType('')}
                                        >{t('searchbar.openForSale')}</span>
                                    </>
                                ) : <a href='#' className='nav-link-no-arrow search-menu-item' onClick={() => setSelectedType('openForSale')}>{t('searchbar.openForSale')}</a>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={2}>
                        {isMobile ? (
                            <a href='#' className={`nav-link-no-arrow search-menu-title ${selectedFilter === 'residential_unit_type' ? 'active' : ''}`}
                                onClick={() => setSelectedFilter('residential_unit_type')}>
                                {t('searchbar.residential_unit_type')}
                            </a>
                        ) : (
                            <h6 className='search-menu-title'>{t('searchbar.residential_unit_type')}</h6>
                        )}
                        <div className='search-menu-options'>
                            {serachkeys.propertiesTypes.map((propertyType) => (
                                <div className='search-menu-item-container'>
                                    <a
                                        key={propertyType}
                                        href='#'
                                        className='nav-link-no-arrow search-menu-item'
                                        onClick={() => {
                                            onSearch(propertyType, 'residential_unit_type', matchedProjects)
                                            setIsExpanded(false)
                                            setSearchString('')
                                        }}
                                    >
                                        {propertyType}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </Col>

                    <Col xs={12} md={2} >
                        {isMobile ? (
                            <a href='#' className={`nav-link-no-arrow search-menu-title ${selectedFilter === 'scope' ? 'active' : ''}`}
                                onClick={() => setSelectedFilter('scope')}>
                                {t('searchbar.scope')}
                            </a>
                        ) : (
                            <h6 className='search-menu-title'>{t('searchbar.scope')}</h6>
                        )}
                        <div className='search-menu-options'>
                            {serachkeys.scope.map((scope) => (
                                <div className='search-menu-item-container'>
                                    <a
                                        key={scope}
                                        href='#'
                                        className='nav-link-no-arrow search-menu-item'
                                        onClick={() => {
                                            onSearch(scope, 'scope', matchedProjects)
                                            setIsExpanded(false)
                                            setSearchString('')
                                        }}
                                    >
                                        {scope}
                                    </a>
                                </div>
                            ))}
                        </div>

                    </Col>
                    <Col xs={12} md={2} >
                        {isMobile ? (
                            <a href='#' className={`nav-link-no-arrow search-menu-title ${selectedFilter === 'status' ? 'active' : ''}`}
                                onClick={() => setSelectedFilter('status')}>
                                {t('searchbar.status')}
                            </a>
                        ) : (
                            <h6 className='search-menu-title'>{t('searchbar.status')}</h6>
                        )}

                        <div className='search-menu-options'>
                            {serachkeys.status.map((status) => (
                                <div className='search-menu-item-container'>
                                    <a
                                        key={status}
                                        href='#'
                                        className='nav-link-no-arrow search-menu-item'
                                        onClick={() => {
                                            onSearch(status, 'status', matchedProjects)
                                            setIsExpanded(false)
                                            setSearchString('')
                                        }}
                                    >
                                        {status}
                                    </a>
                                </div>
                            ))}
                        </div>

                    </Col>
                    <Col xs={12} md={2} className={selectedType === 'portfolio' ? 'd-none' : ''} >
                        {isMobile ? (
                            <a href='#' className={`nav-link-no-arrow search-menu-title ${selectedFilter === 'price_range' ? 'active' : ''}`}
                                onClick={() => setSelectedFilter('price_range')}>
                                {t('searchbar.price_range')}
                            </a>
                        ) : (
                            <h6 className='search-menu-title'>{t('searchbar.price_range')}</h6>
                        )}
                        <div className='search-menu-options'>
                            <p className='search-menu-item'>{selectedPrice[0]} - {selectedPrice[1]}</p>
                            <RangeSlider
                                min={serachkeys.price.lowest}
                                max={serachkeys.price.highest}
                                step={10000}
                                value={selectedPrice}
                                onInput={(value) => setSelectedPrice(value)}
                                className='search-menu-item search-menu-slider'
                            />
                            <a href='#' className='btn btn-sm btn-dark search-menu-apply'
                                onClick={() => {
                                    onSearch(selectedPrice, 'price_range', matchedProjects)
                                    setIsExpanded(false)
                                    setSearchString('')
                                }}
                            >
                                {t('searchbar.apply')}
                            </a>
                        </div>

                    </Col>
                    <Col xs={12} md={2} className={selectedType === 'portfolio' ? 'd-none' : ''} >
                        {isMobile ? (
                            <a href='#' className={`nav-link-no-arrow search-menu-title ${selectedFilter === 'number_of_bedrooms' ? 'active' : ''}`}
                                onClick={() => setSelectedFilter('number_of_bedrooms')}>
                                {t('searchbar.number_of_bedrooms')}
                            </a>
                        ) : (
                            <h6 className='search-menu-title'>{t('searchbar.number_of_bedrooms')}</h6>
                        )}
                        <div className='search-menu-options'>
                            <p className='search-menu-item'>{selectedBedrooms[0]} - {selectedBedrooms[1]}</p>
                            <RangeSlider
                                min={serachkeys.number_of_bedrooms.lowest}
                                max={serachkeys.number_of_bedrooms.highest}
                                step={1}
                                value={selectedBedrooms}
                                onInput={(value) => setSelectedBedrooms(value)}
                                className='search-menu-item search-menu-slider'
                            />
                            <a href='#' className='btn btn-sm btn-dark search-menu-apply'
                                onClick={() => {
                                    onSearch(selectedBedrooms, 'number_of_bedrooms', matchedProjects)
                                    setIsExpanded(false)
                                    setSearchString('')
                                }}
                            >
                                {t('searchbar.apply')}
                            </a>
                        </div>

                    </Col>
                </Row>
                {isMobile && (
                    <Row>
                        <Col xs={12} md={12} >
                            {selectedFilter === 'residential_unit_type' && (
                                <>
                                    {
                                        serachkeys.propertiesTypes.map((propertyType) => (
                                            <div className='search-menu-item-container'>
                                                <a
                                                    key={propertyType}
                                                    href='#'
                                                    className='nav-link-no-arrow search-menu-item'
                                                    onClick={() => {
                                                        onSearch(propertyType, 'residential_unit_type', matchedProjects)
                                                        setIsExpanded(false)
                                                        setSearchString('')
                                                    }}
                                                >
                                                    {propertyType}
                                                </a>
                                            </div>
                                        ))
                                    }
                                </>
                            )}

                            {selectedFilter === 'scope' && (
                                <>
                                    {
                                        serachkeys.scope.map((scope) => (
                                            <div className='search-menu-item-container'>
                                                <a
                                                    key={scope}
                                                    href='#'
                                                    className='nav-link-no-arrow search-menu-item'
                                                    onClick={() => {
                                                        onSearch(scope, 'scope', matchedProjects)
                                                        setIsExpanded(false)
                                                        setSearchString('')
                                                    }}
                                                >
                                                    {scope}
                                                </a>
                                            </div>
                                        ))
                                    }
                                </>
                            )}

                            {selectedFilter === 'status' && (
                                <>
                                    {
                                        serachkeys.status.map((status) => (
                                            <div className='search-menu-item-container'>
                                                <a
                                                    key={status}
                                                    href='#'
                                                    className='nav-link-no-arrow search-menu-item'
                                                    onClick={() => {
                                                        onSearch(status, 'status', matchedProjects)
                                                        setIsExpanded(false)
                                                        setSearchString('')
                                                    }}
                                                >
                                                    {status}
                                                </a>
                                            </div>
                                        ))
                                    }
                                </>
                            )}

                            {selectedFilter === 'price_range' && (
                                <>
                                    <p className='search-menu-item'>{selectedPrice[0]} - {selectedPrice[1]}</p>
                                    <RangeSlider
                                        min={serachkeys.price.lowest}
                                        max={serachkeys.price.highest}
                                        step={10000}
                                        value={selectedPrice}
                                        onInput={(value) => setSelectedPrice(value)}
                                        className='search-menu-item search-menu-slider'
                                    />

                                    <a href='#' className='btn btn-sm btn-dark search-menu-apply'
                                        onClick={() => {
                                            onSearch(selectedPrice, 'price_range', matchedProjects)
                                            setIsExpanded(false)
                                            setSearchString('')
                                        }}
                                    >
                                        {t('searchbar.apply')}
                                    </a>

                                </>
                            )}

                            {selectedFilter === 'number_of_bedrooms' && (
                                <>
                                    <p className='search-menu-item'>{selectedBedrooms[0]} - {selectedBedrooms[1]}</p>
                                    <RangeSlider
                                        min={serachkeys.number_of_bedrooms.lowest}
                                        max={serachkeys.number_of_bedrooms.highest}
                                        step={1}
                                        value={selectedBedrooms}
                                        onInput={(value) => setSelectedBedrooms(value)}
                                        className='search-menu-item search-menu-slider'
                                    />
                                    <a href='#' className='btn btn-sm btn-dark search-menu-apply'
                                        onClick={() => {
                                            onSearch(selectedBedrooms, 'number_of_bedrooms', matchedProjects)
                                            setIsExpanded(false)
                                            setSearchString('')
                                        }}
                                    >
                                        {t('searchbar.apply')}
                                    </a>
                                </>
                            )}
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
}

export default SearchMenu;
