import React, { useRef, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Project from '../Project/Project';
import './RowProjects.css';
import { useTranslation } from 'react-i18next';

import ArrowLink from '../ArrowLink/ArrowLink';



const RowProjects = ({ project1, project2, projectsLink = "" }) => {
    const secondProjectRef = useRef(null);

    const { t } = useTranslation();

    useEffect(() => {
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // If the second-project is in the viewport, add a class to trigger the animation
                    entry.target.classList.add('visible');
                } else {
                    // If the second-project is not in the viewport, remove the class
                    entry.target.classList.remove('visible');
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.5, // Adjust the threshold as needed
        });

        if (secondProjectRef.current) {
            observer.observe(secondProjectRef.current);
        }

        return () => {
            if (secondProjectRef.current) {
                observer.unobserve(secondProjectRef.current);
            }
        };
    }, []);

    return (
        <>
            <Row className="section-content">
                <Col xs={12} sm={12} md={12} lg={6} className="first-project">
                    <Project project={project1} />
                </Col>

                <Col xs={12} sm={12} md={12} lg={6} className="second-project" ref={secondProjectRef}>
                    {project2 && (
                        <Project project={project2} />
                    )}
                </Col>

            </Row>
            {projectsLink && (
                <Row className="more-main-proj" style={{ width: '100%' }}>
                    <Col xs={12} md={12}>
                        <div className='d-flex justify-content-end mt-2'>
                            <ArrowLink href={projectsLink} text={t('home.our_projects')} />
                        </div>
                    </Col>
                </Row>
            )}
        </>

    );
};

export default RowProjects;
