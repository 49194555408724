import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { useTranslation } from 'react-i18next';
import './AboutUs.css';
import aboutUsImage from '../../assets/aboutUs.png';
import aboutUsImage2 from '../../assets/AboutUs2.jpg';
import DeemaAlkassim from '../../assets/Deema AlKassim.png';
import AbdulazizAlkassim from '../../assets/Abdulaziz AlKassim.png';
import AmnaJazeel from '../../assets/Amna Jazeel.png';
import DorisMazraani from '../../assets/Doris.jpeg';
import Abudelah from '../../assets/abdulelah.png';
import ReemaAlkassim from '../../assets/ReemaAlkassim-min.png';
import ImranKhan from '../../assets/ImranKhan-min.png';
import UnknowMale from '../../assets/unknown-male-person.jpg';

import StickyBox from 'react-sticky-box';

import { Row, Col } from 'react-bootstrap';
import { Fade } from "react-awesome-reveal";


const AboutUs = () => {
    const { t } = useTranslation();

    const sepProfileCardRef = React.useRef(null);
    const sepProfileCardRef2 = React.useRef(null);
    const sepProfileCardRef3 = React.useRef(null);
    const sepProfileCardRef4 = React.useRef(null);
    const sepProfileCardRef5 = React.useRef(null);

    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const updateStickyPosition = () => {
        const stickyContainer = document.getElementsByClassName('sticky-box')[0];
        const windowHeight = window.innerHeight;
        const containerHeight = stickyContainer.offsetHeight;

        stickyContainer.style.top = Math.max((windowHeight - containerHeight) / 2, 0) + 'px';
    };

    const handleScroll = () => {
        updateStickyPosition();
        const centerY = window.innerHeight / 2;
        // dummy elemnt on top, has its index considered
        const serviceElements = document.querySelectorAll('.value');
        const newActiveIndex = Array.from(serviceElements).findIndex((element) => {
            const rect = element.getBoundingClientRect();
            return rect.top < centerY && rect.bottom > centerY;

        });

        setActiveIndex(newActiveIndex);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    useEffect(() => {
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // If the second-project is in the viewport, add a class to trigger the animation
                    entry.target.classList.add('visible');
                } else {
                    // If the second-project is not in the viewport, remove the class
                    entry.target.classList.remove('visible');
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.5, // Adjust the threshold as needed
        });

        if (sepProfileCardRef.current) {
            observer.observe(sepProfileCardRef.current);
        }
        if (sepProfileCardRef2.current) {
            observer.observe(sepProfileCardRef2.current);
        }
        if (sepProfileCardRef3.current) {
            observer.observe(sepProfileCardRef3.current);
        }
        if (sepProfileCardRef4.current) {
            observer.observe(sepProfileCardRef4.current);
        }
        if (sepProfileCardRef5.current) {
            observer.observe(sepProfileCardRef5.current);
        }

        return () => {
            if (sepProfileCardRef.current) {
                observer.unobserve(sepProfileCardRef.current);
            }
            if (sepProfileCardRef2.current) {
                observer.unobserve(sepProfileCardRef2.current);
            }

            if (sepProfileCardRef3.current) {
                observer.unobserve(sepProfileCardRef3.current);
            }

            if (sepProfileCardRef4.current) {
                observer.unobserve(sepProfileCardRef4.current);
            }

            if (sepProfileCardRef5.current) {
                observer.unobserve(sepProfileCardRef5.current);
            }
        };

    }, []);



    return (
        <>
            <Navbar />

            <Fade>
                <div className="about-us-section">

                    <Row style={{ width: '100%' }}>
                        <Col xs={12} md={12} lg={4} className='about-us-text-section left-section'>
                            <h3 className="about-us-title">{t('aboutUs.title')}</h3>

                            <p className="about-us-text">
                                {t('aboutUs.text')}
                            </p>

                            <p className="about-us-text">
                                {t('aboutUs.text2')}
                            </p>
                        </Col>
                        <Col xs={12} md={12} lg={8}>
                            <img src={aboutUsImage} alt="about us" className="about-us-image" />
                        </Col>
                    </Row>

                </div>
            </Fade>


            <div className="about-us-section text-section">
                <Fade>
                    <Row>
                        <Col>
                            <p className="about-us-section-text" dangerouslySetInnerHTML={{ __html: t('aboutUs.text3') }} />
                            <p className="about-us-section-text">
                                {t('aboutUs.text4')}
                            </p>
                        </Col>
                    </Row>
                </Fade>
            </div>

            <div className="about-us-section">
                <Fade>
                    <Row>
                        <Col xs={12} md={12} lg={6}>
                            <div className="about-us-image-container">
                                <img src={aboutUsImage2} alt="about us" className="about-us-image center" />
                            </div>
                        </Col>

                        <Col xs={12} md={12} lg={6} className='about-us-text-section right-section'>
                            <h3 className="about-us-title">{t('aboutUs.our_history')}</h3>

                            <p className="about-us-text">
                                {t('aboutUs.our_history_text')}
                            </p>

                        </Col>
                    </Row>
                </Fade>
            </div>

            <div className="about-us-section">
                <Fade>
                    <Row>
                        <Col xs={4} md={4} lg={6} className='about-us-text-section'>
                            <StickyBox className='sticky-box'>
                                <h3 className="about-us-title">{t('aboutUs.our_values')}</h3>
                            </StickyBox>
                        </Col>

                        <Col xs={8} md={8} lg={6} className='about-us-text-section'>

                            {[1, 2, 3, 4, 5, 6].map((index) => (
                                <div
                                    className={`value ${activeIndex === index - 1 ? 'active' : ''} ${index === 1 ? 'first' : ''} ${index === 6 ? 'last' : ''}`}
                                    key={index}
                                >
                                    <p>
                                        {t(`aboutUs.value${index}`)}
                                    </p>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </Fade>
            </div>

            <Fade>
                <div className="about-us-section team-section">
                    <Row>
                        <h3 className="about-us-title">
                            {t('aboutUs.our_team')}
                        </h3>
                    </Row>

                    <Row style={{ width: '100%' }}>
                        <Col xs={12} md={12} lg={6}>
                            <div className="profile-card">
                                <Row>
                                    <Col xs={7} md={7} lg={7}>
                                        <div className="profile-text-container">
                                            <p className="profile-position">{t('aboutUs.ceo')}</p>
                                            <h5 className="profile-name">{t('aboutUs.aziz_alkassim')}</h5>
                                        </div>
                                    </Col>
                                    <Col xs={5} md={5} lg={5}>
                                        <div className="profile-image-container">
                                            <img src={AbdulazizAlkassim} alt="Abdulaziz AlKassim" className="profile-image center" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <div className="profile-card sep" ref={sepProfileCardRef}>
                                <Row>
                                    <Col xs={7} md={7} lg={7}>
                                        <div className="profile-text-container">
                                            <p className="profile-position">{t('aboutUs.head_architect')}</p>
                                            <h5 className="profile-name">{t('aboutUs.deema_alkassim')}</h5>
                                        </div>
                                    </Col>
                                    <Col xs={5} md={5} lg={5}>
                                        <div className="profile-image-container">
                                            <img src={DeemaAlkassim} alt="Deema AlKassim" className="profile-image center" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                    </Row>

                    <Row style={{ width: '100%' }}>
                        <Col xs={12} md={12} lg={6}>
                            <div className="profile-card">
                                <Row>
                                    <Col xs={7} md={7} lg={7}>
                                        <div className="profile-text-container">
                                            <p className="profile-position">{t('aboutUs.finance_manager')}</p>
                                            <h5 className="profile-name">{t('aboutUs.imran_khan')}</h5>
                                        </div>
                                    </Col>
                                    <Col xs={5} md={5} lg={5}>
                                        <div className="profile-image-container">
                                            <img src={ImranKhan} alt="Imran Khan" className="profile-image center" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <div className="profile-card sep" ref={sepProfileCardRef2}>
                                <Row>
                                    <Col xs={7} md={7} lg={7}>
                                        <div className="profile-text-container">
                                            <p className="profile-position">{t('aboutUs.architect')}</p>
                                            <h5 className="profile-name">{t('aboutUs.amna_jazeel')}</h5>
                                        </div>
                                    </Col>
                                    <Col xs={5} md={5} lg={5}>
                                        <div className="profile-image-container">
                                            <img src={AmnaJazeel} alt="Amna Jazeel" className="profile-image center" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                    </Row>
                    <Row style={{ width: '100%' }}>
                        <Col xs={12} md={12} lg={6}>
                            <div className="profile-card">
                                <Row>
                                    <Col xs={7} md={7} lg={7}>
                                        <div className="profile-text-container">
                                            <p className="profile-position">{t('aboutUs.marketing_manager')}</p>
                                            <h5 className="profile-name">{t('aboutUs.reema_alkassim')}</h5>
                                        </div>
                                    </Col>
                                    <Col xs={5} md={5} lg={5}>
                                        <div className="profile-image-container">
                                            <img src={ReemaAlkassim} alt="Reema AlKassim" className="profile-image center" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <div className="profile-card sep" ref={sepProfileCardRef3}>
                                <Row>
                                    <Col xs={7} md={7} lg={7}>
                                        <div className="profile-text-container">
                                            <p className="profile-position">{t('aboutUs.assistant_marketing_officer')}</p>
                                            <h5 className="profile-name">{t('aboutUs.doris_mazraani')}</h5>
                                        </div>
                                    </Col>
                                    <Col xs={5} md={5} lg={5}>
                                        <div className="profile-image-container">
                                            <img src={DorisMazraani} alt="Doris Mazraani" className="profile-image center" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                    </Row>
                    <Row style={{ width: '100%' }}>
                        <Col xs={12} md={12} lg={6}>
                            <div className="profile-card">
                                <Row>
                                    <Col xs={7} md={7} lg={7}>
                                        <div className="profile-text-container">
                                            <p className="profile-position">{t('aboutUs.designer')}</p>
                                            <h5 className="profile-name">{t('aboutUs.AsmaAlderaan')}</h5>
                                        </div>
                                    </Col>
                                    <Col xs={5} md={5} lg={5}>
                                        <div className="profile-image-container">
                                            <img src={UnknowMale} alt="Asma Alderaan" className="profile-image center" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <div className="profile-card sep" ref={sepProfileCardRef4}>
                                <Row>
                                    <Col xs={7} md={7} lg={7}>
                                        <div className="profile-text-container">
                                            <p className="profile-position">{t('aboutUs.hr_officer')}</p>
                                            <h5 className="profile-name">{t('aboutUs.nehal_alali')}</h5>
                                        </div>
                                    </Col>
                                    <Col xs={5} md={5} lg={5}>
                                        <div className="profile-image-container">
                                            <img src={UnknowMale} alt="about us" className="profile-image center" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                    </Row>
                    <Row style={{ width: '100%' }}>
                        <Col xs={12} md={12} lg={6}>
                            <div className="profile-card">
                                <Row>
                                    <Col xs={7} md={7} lg={7}>
                                        <div className="profile-text-container">
                                            <p className="profile-position">{t('aboutUs.site_engineer')}</p>
                                            <h5 className="profile-name">{t('aboutUs.abdulelah_almarzouk')}</h5>
                                        </div>
                                    </Col>
                                    <Col xs={5} md={5} lg={5}>
                                        <div className="profile-image-container">
                                            <img src={Abudelah} alt="Abdulelah Almarzouk" className="profile-image center" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <div className="profile-card sep" ref={sepProfileCardRef5}>

                            </div>
                        </Col>

                    </Row>
                </div>
            </Fade>



            <Footer />
        </>
    );
};

export default AboutUs;
