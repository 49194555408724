import React, { useRef, useEffect, useState } from "react";

export default function AutoPlaySilentVideo(props) {
    const videoRef = useRef(null);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    useEffect(() => {
        videoRef.current.addEventListener("loadeddata", handleVideoLoaded);
        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener("loadeddata", handleVideoLoaded);
            }
        };
    }, []);

    const handleVideoLoaded = () => {
        setIsVideoLoaded(true);
    };

    return (
        <>
            {!isVideoLoaded && (
                <img
                    className={props.className}
                    src={props.poster}
                    alt="Video Poster"
                />
            )}
            <video
                className={props.className}
                ref={videoRef}
                loop
                autoPlay
                muted
                playsInline
                controls={props.controls ? true : false}
                style={{ display: isVideoLoaded ? "block" : "none" }}
            >
                <source src={props.video} type="video/mp4" />
            </video>
        </>
    );
}