import React, { useEffect } from "react"

import ProjectDetails from "./ProjectDetails";
import { Projects } from "../../Projects/Main";
import { Projects_ar } from "../../Projects/Main";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NotFound from "../NotFoundPage/NotFound";


const Main = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const queryParams = new URLSearchParams(location.search);
    const project_id = queryParams.get("project_id");

    let ProjectsList = Projects;

    if (i18n.language === "ar") {
        ProjectsList = Projects_ar;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const project = ProjectsList.find(project => project.id === project_id);
    if (!project) {
        return (
            <NotFound />
        );
    }

    return (
        <ProjectDetails project={project} />
    );
};

export default Main;
