import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';


const NotFound = () => {
    const { t } = useTranslation();
    return (
        <>
            <Navbar />
            <div className="not-found">
                <h1
                    style={{
                        textAlign: "center",
                        margin: "auto",
                        height: "80vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >{t("common.project_not_found")}</h1>
            </div>
            <Footer />
        </>
    );
};

export default NotFound;
