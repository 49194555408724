
import mainImage from "../assets/projects/Mohamadiya/EAST-ELEVATION-min.jpg";
import mainFloor from "../assets/projects/Mohamadiya/main-floor.gif";
import moreimage1 from "../assets/projects/Mohamadiya/WESTSOUTH-ELEVATION-min.jpg";
import moreimage2 from "../assets/projects/Mohamadiya/images/5.jpg";
import openerVideo from "../assets/projects/Mohamadiya/villa-moh.mp4";

import image2 from "../assets/projects/Mohamadiya/EAST-ELEVATION-min.jpg";
import image9 from "../assets/projects/Mohamadiya/GROUNDFLOOR-LIVINGROOMPOOL-min.jpg";
import image11 from "../assets/projects/Mohamadiya/WESTSOUTH-ELEVATION-min.jpg";

import img1 from "../assets/projects/Mohamadiya/images/1.jpg";
import img2 from "../assets/projects/Mohamadiya/images/2.jpg";
import img3 from "../assets/projects/Mohamadiya/images/3.jpg";
import img4 from "../assets/projects/Mohamadiya/images/4.jpg";
import img5 from "../assets/projects/Mohamadiya/images/5.jpg";
import img6 from "../assets/projects/Mohamadiya/images/6.jpg";
import img7 from "../assets/projects/Mohamadiya/images/7.jpg";
import img8 from "../assets/projects/Mohamadiya/images/8.jpg";
import img9 from "../assets/projects/Mohamadiya/images/9.jpg";
import img10 from "../assets/projects/Mohamadiya/images/10.jpg";
import img11 from "../assets/projects/Mohamadiya/images/11.jpg";
import img12 from "../assets/projects/Mohamadiya/images/12.jpg";
import img13 from "../assets/projects/Mohamadiya/images/13.jpg";
import img15 from "../assets/projects/Mohamadiya/images/15.jpg";
import img16 from "../assets/projects/Mohamadiya/images/16.jpg";
import img17 from "../assets/projects/Mohamadiya/images/17.jpg";
import img18 from "../assets/projects/Mohamadiya/images/18.jpg";
import img19 from "../assets/projects/Mohamadiya/images/19.jpg";
import img20 from "../assets/projects/Mohamadiya/images/20.jpg";
import img21 from "../assets/projects/Mohamadiya/images/21.jpg";
import img22 from "../assets/projects/Mohamadiya/images/22.jpg";
import img23 from "../assets/projects/Mohamadiya/images/23.jpg";
import img24 from "../assets/projects/Mohamadiya/images/24.png";
import img25 from "../assets/projects/Mohamadiya/images/25.jpg";
import img26 from "../assets/projects/Mohamadiya/images/26.jpg";
import img27 from "../assets/projects/Mohamadiya/images/27.jpg";
import img28 from "../assets/projects/Mohamadiya/images/28.jpg";

import thm1 from "../assets/projects/Mohamadiya/images/thumb/1_tn.jpg";
import thm2 from "../assets/projects/Mohamadiya/images/thumb/2_tn.jpg";
import thm3 from "../assets/projects/Mohamadiya/images/thumb/3_tn.jpg";
import thm4 from "../assets/projects/Mohamadiya/images/thumb/4_tn.jpg";
import thm5 from "../assets/projects/Mohamadiya/images/thumb/5_tn.jpg";
import thm6 from "../assets/projects/Mohamadiya/images/thumb/6_tn.jpg";
import thm7 from "../assets/projects/Mohamadiya/images/thumb/7_tn.jpg";
import thm8 from "../assets/projects/Mohamadiya/images/thumb/8_tn.jpg";
import thm9 from "../assets/projects/Mohamadiya/images/thumb/9_tn.jpg";
import thm10 from "../assets/projects/Mohamadiya/images/thumb/10_tn.jpg";
import thm11 from "../assets/projects/Mohamadiya/images/thumb/11_tn.jpg";
import thm12 from "../assets/projects/Mohamadiya/images/thumb/12_tn.jpg";
import thm13 from "../assets/projects/Mohamadiya/images/thumb/13_tn.jpg";
import thm15 from "../assets/projects/Mohamadiya/images/thumb/15_tn.jpg";
import thm16 from "../assets/projects/Mohamadiya/images/thumb/16_tn.jpg";
import thm17 from "../assets/projects/Mohamadiya/images/thumb/17_tn.jpg";
import thm18 from "../assets/projects/Mohamadiya/images/thumb/18_tn.jpg";
import thm19 from "../assets/projects/Mohamadiya/images/thumb/19_tn.jpg";
import thm20 from "../assets/projects/Mohamadiya/images/thumb/20_tn.jpg";
import thm21 from "../assets/projects/Mohamadiya/images/thumb/21_tn.jpg";
import thm22 from "../assets/projects/Mohamadiya/images/thumb/22_tn.jpg";
import thm23 from "../assets/projects/Mohamadiya/images/thumb/23_tn.jpg";
import thm24 from "../assets/projects/Mohamadiya/images/thumb/24_tn.jpg";
import thm25 from "../assets/projects/Mohamadiya/images/thumb/25_tn.jpg";
import thm26 from "../assets/projects/Mohamadiya/images/thumb/26_tn.jpg";
import thm27 from "../assets/projects/Mohamadiya/images/thumb/27_tn.jpg";
import thm28 from "../assets/projects/Mohamadiya/images/thumb/28_tn.jpg";

import thum2 from "../assets/projects/Mohamadiya/thumbnails/EAST-ELEVATION-min_tn.jpg";
import thum9 from "../assets/projects/Mohamadiya/thumbnails/GROUNDFLOOR-LIVINGROOMPOOL-min_tn.jpg";
import thum11 from "../assets/projects/Mohamadiya/thumbnails/WESTSOUTH-ELEVATION-min_tn.jpg";

import plan from "../assets/projects/Mohamadiya/site plan/plan.png";
import villa1 from "../assets/projects/Mohamadiya/site plan/villa1.png";
import villa2 from "../assets/projects/Mohamadiya/site plan/villa2.png";
import villa3 from "../assets/projects/Mohamadiya/site plan/villa3.png";


import Basement from "../assets/projects/Mohamadiya/floor plans/basment.png";
import GroundFloor from "../assets/projects/Mohamadiya/floor plans/ground.png";
import FirstFloor from "../assets/projects/Mohamadiya/floor plans/1st.png";
import SecondFloor from "../assets/projects/Mohamadiya/floor plans/2nd.png";

export const Mohamadiyah_ar = {
    id: "mohamadiyah",
    name: "فلل المحمدية",
    residential_Unit_Type: "فيلا",
    scope: "تصميم و تطوير",
    status: "مكتمل",
    starting_price: 4900000,
    starting_price_text: "4 مليون و 900 ألف",
    number_of_bedrooms: 4,
    location: "المحمدية",
    area: "1,599 م²",
    stats: {
        units: {
            value: 3,
            unit: ""
        },
        floors_in_unit: {
            value: 4,
            unit: ""
        },
        built_up_area_per_unit: {
            value: 533,
            unit: "م²"
        },
        plot_area_per_unit: {
            value: 300,
            unit: "م²"
        }
    },
    nearby_description: "يتميز المشروع بموقع متميز. تقع المنازل خلف تقاطع الملك عبد الله مع التخصصي، جميع الفلل على شارعين متظاهرين مما يسهل الدخول والخروج للوحدات السكنية من شوارع مختلفه اما في مقدة المنزل او خلفه. محطة المترو الجديدة تبعد 120 متر ومستشفى سليمان الحبيب الطبي على بعد 250 متر فقط. ينبض الحي المحيط بالحياة ويقدم خدمات تتوسع من المطاعم والمحلات التجارية والخدمات الطبية.",
    team: [
        "عبدالعزيز القاسم",
        "ديمه القاسم",
        "عبدالرحمن الشوبكي",
        "آمنه جزيل"
    ],
    opener_video: openerVideo,
    main_images: [
        mainImage,
        moreimage1,
        moreimage2,
    ],
    text_sections: [
        "في حي المحمدية بالرياض، تم تصميم فيلات لتعكس الديناميكيات لعائلة سعودية. يبلغ مسطح البناء لكل فيلا 533 مترًا مربعًا، بما في ذلك مساحة الفيلا التي تبلغ 300 متر مربعًا، ويمزج بسلاسة بين مناطق المعيشة الداخلية والخارجية لتعزيز الراحة والربط بالخارج.",
        "يتميز المشروع بموقع متميز. تقع المنازل خلف تقاطع الملك عبد الله مع التخصصي، جميع الفلل على شارعين متظاهرين مما يسهل الدخول والخروج للوحدات السكنية من شوارع مختلفه اما في مقدة المنزل او خلفه. محطة المترو الجديدة تبعد 120 متر ومستشفى سليمان الحبيب الطبي على بعد 250 متر فقط. ينبض الحي المحيط بالحياة ويقدم خدمات تتوسع من المطاعم والمحلات التجارية والخدمات الطبية.",
        "مستوحى من جوهر العائلة السعودية، يعكس التصميم قيمنا في كل من التنظيم المكاني ومعالجة الواجهات. تسود الخصوصية، مما يضمن الراحة الفردية والهدوء ويسمح بالاتصال عند الرغبة. يعمل التصميم المعماري على تنسيق تدفق الحركة، وتمنع التعارض لتسهيل التعايش داخل المساحة المشتركة.",
        "تم تصميم المبنى من خطوات مدروسة بدقة. أولاً، انقسم الحجم الأولي إلى ثلاثة مستويات، ثم تحركت المستويان السفليان لليمين واليسار، فأضفيا تفاعلاً بصرياً وحركياً. بعد ذلك، تماثلت التراجعات والبروزات على المستوى الثالث لإنشاء وحدات حجمية فردية ضمن الشكل العام. وأخيراً، أضيفت الشرفات والنوافذ لتضفي الحيوية على التصميم وتربطه بالبيئة المحيطة وتغني تجربة المستخدم. يمثل هذا التقدم، من الحجم إلى التشكل والتنفيذ، جوهر الهوية المعمارية للمبنى.",
        "تحتوي كل فيلا على مداخل تواجه شارعين على الأقل من الشوارع الثلاثة المحيطة بالموقع. تلبي هذه المرونة الاحتياجات والتفضيلات الوظيفية المتنوعة داخل المنزل. الواجهة الغربية مصمتة للحماية من أشعة الشمس المباشرة. وفي الشرق، يحتضن التصميم الشفافية باستخدام الزجاج، وتقوم بترحيب ضوء الصباح. باتجاه الجنوب، تحافظ الواجهة على حجاب من الخصوصية، يكشف لمحات من وإلى الداخل، باستخدام النوافذ مع الحفاظ على الشعور بالعزلة."
    ],

    section_images: {
        1: [mainFloor],
    },

    plans_cover: plan,
    plnas_description: "تتميز الفيلا بأربع مناطق معيشة، ثلاثة منها تمزج بسلاسة بين الداخل والخارج، مما يخلق مساحات متعددة الاستخدام. تشمل الفيلا أيضًا أربع غرف نوم، بما في ذلك جناح رئيسي مع خزانة ملابس وحمام مزدوج. كما تتوفر مساحة مخصصة للمكتب وغرفة رياضية. وتتوفر منطقة ترفيه على السطح مع منطقة شواء. كما تحتوي الفيلا على مطبخ ديرتي مدخله منفصل بالإضافة إلى مطابخ صغيرة على كل طابق.",
    location_dev: {
        latitude: 24.72551744,
        longitude: 46.65553273,
        google_maps_url: "https://maps.app.goo.gl/5TyqwTZv8YQxNDgu5",
    },
    images: [
        {
            image: image2,
            description: "منظور الواجهة الشرقية للمشروع.",
            thumbnail: thum2,
        },
        {
            image: img1,
            thumbnail: thm1,
        },
        {
            image: img2,
            thumbnail: thm2,
        },
        {
            image: img3,
            thumbnail: thm3,
        },
        {
            image: img4,
            thumbnail: thm4,
        },
        {
            image: img5,
            thumbnail: thm5,
        },
        {
            image: img6,
            thumbnail: thm6,
        },
        {
            image: img7,
            thumbnail: thm7,
        },
        {
            image: img8,
            thumbnail: thm8,
        },
        {
            image: img9,
            thumbnail: thm9,
        },
        {
            image: img10,
            thumbnail: thm10,
        },
        {
            image: img11,
            thumbnail: thm11,
        },
        {
            image: img12,
            thumbnail: thm12,
        },
        {
            image: img13,
            thumbnail: thm13,
        },
        {
            image: img15,
            thumbnail: thm15,
        },
        {
            image: img16,
            thumbnail: thm16,
        },
        {
            image: img18,
            thumbnail: thm18,
        },
        {
            image: img19,
            thumbnail: thm19,
        },
        {
            image: img20,
            thumbnail: thm20,
        },
        {
            image: img21,
            thumbnail: thm21,
        },
        {
            image: img22,
            thumbnail: thm22,
        },
        {
            image: img23,
            thumbnail: thm23,
        },
        {
            image: img24,
            thumbnail: thm24,
        },
        {
            image: img25,
            thumbnail: thm25,
        },
        {
            image: img26,
            thumbnail: thm26,
        },
        {
            image: img27,
            thumbnail: thm27,
        },
        {
            image: img28,
            thumbnail: thm28,
        },
        {
            image: img17,
            description: "الدور الأرضي ، مظور للمطبخ.",
            thumbnail: thm17,
        },
        {
            image: image9,
            description: "الدور الأرضي، منظور من المسبح إلى صالة المعيشة.",
            thumbnail: thum9,
        },
        {
            image: image11,
            description: "منظور الواجهة الجنوبية والغربية للمشروع.",
            thumbnail: thum11,
        },
    ],
    hasPlansOverlay: true,
    hasPlans: true,
    hasLocation: true,
    forSale: false,
    plans: {
        cover: plan,
        villa1: villa1,
        villa2: villa2,
        villa3: villa3,
    },
    villaInof: {
        villa3: {
            price: 0,
            price_text: "غير متاح",
            size: 533,
            availabile: false,
            name: "فيلا 3",
        },
        villa2: {
            price: 0,
            price_text: "غير متاح",
            size: 533,
            availabile: false,
            name: "فيلا 2",
        },
        villa1: {
            price: 0,
            price_text: "غير متاح",
            size: 533,
            availabile: false,
            name: "فيلا 1",
        }

    },
    floorPlans: [
        {
            name: "قبو",
            img: Basement,
            area: "112 م²",
            descriptionList: [
                "1 منطقة معيشة",
                "2 مطبخ مفتوح",
                "3 مكتب",
                "4 غرفة تمرين",
                "5 مناطق خارجية",
                "6 مرحاض",
                "7 مصعد",
                "8 الدرج إلى البوابة",
            ],
        },
        {
            name: "أرضي",
            img: GroundFloor,
            area: "190 م²",
            descriptionList: [
                "1 مناطق خارجية",
                "2 استقبال",
                "3 منطقة المعيشة",
                "4 منطقة الطعام",
                "5 مسبح",
                "6 مرحاض",
                "7 مصعد",
                "8 الدرج",
                "9 غرفة غسيل",
                "10 مطبخ",
                "11 غرفة خادمة",
                "12 غرفة سائق",
                "13 مواقف سيارات",
            ],
        },
        {
            name: "الدور الأول",
            img: FirstFloor,
            area: "171 م²",
            descriptionList: [
                "1 غرفة نوم رئيسية",
                "2 حمام رئيسي وخزانة ملابس",
                "3 غرفة نوم مفردة",
                "4 غرف نوم مزدوجة",
                "5 تراس",
                "6 مرحاض",
                "7 مصعد",
                "8 الدرج",
                "9 مطبخ",
            ],
        },
        {
            name: "الدور الثاني",
            img: SecondFloor,
            area: "60 م²",
            descriptionList: [
                "1 منطقة معيشة",
                "2 منطقة المعيشة الخارجية",
                "3 منطقة الطعام الخارجية",
                "4 مطبخ خارجي",
                "5 منطقة الخدمة",
                "6 مرحاض",
                "7 مصعد",
                "8 الدرج",
            ],
        },
    ],
};