import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Alert, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import ArrowLink from '../ArrowLink/ArrowLink';
import { useLocation } from 'react-router-dom';
import NotFound from '../NotFoundPage/NotFound';
import ReCAPTCHA from 'react-google-recaptcha';


import { Projects } from '../../Projects/Main';
import { Projects_ar } from "../../Projects/Main";
import { APP_ROUTES } from '../../const/const';




const BookTour = () => {
    const { t, i18n } = useTranslation();

    let ProjectList = Projects;
    if (i18n.language === 'ar') {
        ProjectList = Projects_ar;
    }

    const forSaleProjects = ProjectList.filter(project => project.forSale);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const project_id = queryParams.get("project_id");
    const project = ProjectList.find(project => project.id === project_id);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };
    const [msg, setMsg] = useState(null);
    const [msgType, setMsgType] = useState('danger');
    const [loading, setLoading] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const [formState, setFormState] = useState({
        preferredProject: project && project.id ? project.id : '',
        preferredObject: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        occupation: 'private_sector_employee',
        occupation_other: '',
        company: '',
        maritalStatus: 'single',
        purchasingPurpose: 'own_use',
        purchaseMethod: 'cash',
        numberOfFamilyMembers: '',
        description: '',
        birthDate: '',
        gender: '',
        disclaimerAccepted: false,
        howDidYouHearAboutUs: '',
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (msgType === 'success') {
            setTimeout(() => {
                setMsg(null);
            }, 3000);
        }
    }, [msgType]);


    useEffect(() => {
        if (project) {
            setFormState({
                ...formState,
                preferredObject: Object.entries(project.villaInof).find(([key, villa]) => villa.availabile)[0]
            });
        }
    }, [project]);

    if (!project || !project.forSale) {
        return (
            <NotFound />
        );
    }

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            setFormState({ ...formState, [name]: checked });
            return;
        }

        setFormState({ ...formState, [name]: value });
    }

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        if (!recaptchaValue) {
            setMsg(t('common.recaptcha_error'));
            setMsgType('danger');
            setLoading(false);
            return;
        }

        if (!formState.disclaimerAccepted) {
            setMsg(t('book_tour.disclaimer_error'));
            setMsgType('danger');
            setLoading(false);
            return;
        }

        const jsonBody = {
            preferredProject: formState.preferredProject,
            preferredObject: formState.preferredObject,
            firstName: formState.firstName,
            lastName: formState.lastName,
            email: formState.email,
            phone: formState.phone,
            occupation: formState.occupation === 'other' ? formState.occupation_other : formState.occupation,
            company: formState.company,
            maritalStatus: formState.maritalStatus,
            numberOfFamilyMembers: formState.numberOfFamilyMembers,
            purchasingPurpose: formState.purchasingPurpose,
            purchaseMethod: formState.purchaseMethod,
            description: formState.description,
            birthDate: formState.birthDate,
            gender: formState.gender,
            disclaimerAccepted: formState.disclaimerAccepted,
            howDidYouHearAboutUs: formState.howDidYouHearAboutUs,
        }

        try {
            const response = await fetch('tour.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': i18n.language,
                },
                body: JSON.stringify({ ...jsonBody, 'g-recaptcha-response': recaptchaValue }),
            });

            const result = await response.json();

            if (result.status === 'error') {
                setMsgType('danger');
                setMsg(result.msg);
            } else {
                setSubmitSuccess(true);
                setMsgType('success');
                setMsg(t('common.success'));
                setFormState({
                    preferredProject: project && project.id ? project.id : '',
                    preferredObject: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    occupation: 'private_sector_employee',
                    occupation_other: '',
                    company: '',
                    maritalStatus: 'single',
                    purchasingPurpose: '',
                    purchaseMethod: '',
                    numberOfFamilyMembers: '',
                    description: '',
                    gender: '',
                    birthDate: '',
                    disclaimerAccepted: false,
                    howDidYouHearAboutUs: '',
                });
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
            setMsgType('danger');
            setMsg(t('common.server_error'));
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Navbar />
            {submitSuccess ? (
                <div className="text-center d-flex flex-column align-items-center justify-content-center">
                    <Row className='submit-success-section'>
                        <Col xs={12} md={12} lg={12}>
                            <h3>
                                {t('common.success')}
                            </h3>
                        </Col>
                    </Row>

                    <div className="d-flex flex-row mt-5">
                        <ArrowLink href={APP_ROUTES.HOME} text={t('common.back_to_home')} className='mx-3' />
                        <ArrowLink href='about:blank' text={t('common.close_tab')} className='mx-3' />
                    </div>
                </div>

            ) : (
                <Row className='contact-us-section'>
                    <Col xs={12} md={12} lg={5}>
                        <h3>
                            {t('book_tour.title')}
                        </h3>
                    </Col>
                    <Col xs={12} md={12} lg={7}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="preferredProject">
                                <Form.Label>{t('book_tour.preferred_project')}</Form.Label>
                                <Form.Control as="select" value={formState.preferredProject} name="preferredProject" onChange={handleInputChange} required>
                                    <option value=""></option>
                                    {forSaleProjects.map((project) => (
                                        <option key={project.id} value={project.id}>{project.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="preferredObject">
                                <Form.Label>{t('book_tour.preferred_object')}</Form.Label>
                                <Form.Control as="select" value={formState.preferredObject} name="preferredObject" onChange={handleInputChange} required>
                                    <option value=""></option>
                                    {formState.preferredProject && Object.entries(ProjectList.find(project => project.id === formState.preferredProject).villaInof).map(([key, villa]) => {
                                        if (villa.availabile) {
                                            return (
                                                <option
                                                    selected={villa.name === formState.preferredObject}
                                                    key={key} value={key}>{villa.name}</option>
                                            );
                                        }
                                    }
                                    )
                                    }
                                </Form.Control>
                            </Form.Group>

                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="firstName">
                                        <Form.Label>{t('book_tour.first_name')}</Form.Label>
                                        <Form.Control type="text" placeholder={t('book_tour.first_name')} value={formState.firstName} name="firstName" onChange={handleInputChange} required />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="lastName">
                                        <Form.Label>{t('book_tour.last_name')}</Form.Label>
                                        <Form.Control type="text" placeholder={t('book_tour.last_name')} value={formState.lastName} name="lastName" onChange={handleInputChange} required />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="email">
                                        <Form.Label>{t('book_tour.email')}</Form.Label>
                                        <Form.Control type="email" placeholder={t('book_tour.email')} value={formState.email} name="email" onChange={handleInputChange} required />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="phone">
                                        <Form.Label>{t('book_tour.phone')}</Form.Label>
                                        <Form.Control type="text" placeholder={t('book_tour.phone')} value={formState.phone} name="phone" onChange={handleInputChange} required />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="occupation">
                                        <Form.Label>{t('book_tour.occupation')}</Form.Label>
                                        <Form.Control as="select" value={formState.occupation} name="occupation" onChange={handleInputChange} required>
                                            <option value="private_sector_employee">{t('book_tour.private_sector_employee')}</option>
                                            <option value="government_employee">{t('book_tour.government_employee')}</option>
                                            <option value="other">{t('book_tour.other')}</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="company">
                                        <Form.Label>{t('book_tour.company')}</Form.Label>
                                        <Form.Control type="text" placeholder={t('book_tour.company')} value={formState.company} name="company" onChange={handleInputChange} required />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {formState.occupation === 'other' &&
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <Form.Group controlId="company">
                                            <Form.Label>{t('book_tour.occupation')}</Form.Label>
                                            <Form.Control type="text" placeholder={t('book_tour.occupation')} value={formState.occupation_other} name="occupation_other" onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col>
                                    <Form.Group controlId="maritalStatus">
                                        <Form.Label>{t('book_tour.marital_status')}</Form.Label>
                                        <Form.Control as="select" value={formState.maritalStatus} name="maritalStatus" onChange={handleInputChange} required>
                                            <option value="single">{t('book_tour.single')}</option>
                                            <option value="married">{t('book_tour.married')}</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                {formState.maritalStatus === 'married' &&
                                    <Col>
                                        <Form.Group controlId="numberOfFamilyMembers">
                                            <Form.Label>{t('book_tour.number_of_family_members')}</Form.Label>
                                            <Form.Control type="text" placeholder={t('book_tour.number_of_family_members')} value={formState.numberOfFamilyMembers} name="numberOfFamilyMembers" onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                }
                            </Row>

                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="birthDate">
                                        <Form.Label>{t('common.birth_date')}</Form.Label>
                                        <Form.Control type="date" placeholder={t('common.birth_date')} value={formState.birthDate} name="birthDate" onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="gender">
                                        <Form.Label>{t('common.gender')}</Form.Label>
                                        <Form.Control as="select" value={formState.gender} name="gender" onChange={handleInputChange} required>
                                            <option value=""></option>
                                            <option value="male">{t('common.male')}</option>
                                            <option value="female">{t('common.female')}</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="purchasingPurpose">
                                        <Form.Label>{t('book_tour.buying_purpose')}</Form.Label>
                                        <Form.Control as="select" value={formState.purchasingPurpose} name="purchasingPurpose" onChange={handleInputChange} required>
                                            <option value=""></option>
                                            <option
                                                selected={formState.purchasingPurpose === 'own_use'}
                                                value="own_use">{t('book_tour.own_use')}
                                            </option>
                                            <option
                                                selected={formState.purchasingPurpose === 'investment'}
                                                value="investment"
                                            >{t('book_tour.investment')}</option>
                                            <option
                                                selected={formState.purchasingPurpose === 'other'}
                                                value="other">{t('book_tour.other')}</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="purchaseMethod">
                                        <Form.Label>{t('book_tour.buy_method')}</Form.Label>
                                        <Form.Control as="select" value={formState.purchaseMethod} name="purchaseMethod" onChange={handleInputChange} required>
                                            <option value=""></option>
                                            <option
                                                selected={formState.purchaseMethod === 'cash'}
                                                value="cash">{t('book_tour.cash')}</option>
                                            <option
                                                selected={formState.purchaseMethod === 'bank_loan'}
                                                value="bank_loan">{t('book_tour.bank_loan')}</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="howDidYouHearAboutUs">
                                <Form.Label>{t('book_tour.how_did_you_hear_about_us')}</Form.Label>
                                <Form.Control as="select" value={formState.howDidYouHearAboutUs} name="howDidYouHearAboutUs" onChange={handleInputChange} required>
                                    <option value=""></option>
                                    <option value="Instagram">{t('book_tour.instagram')}</option>
                                    <option value="TikTok">{t('book_tour.tiktok')}</option>
                                    <option value="X">{t('book_tour.x')}</option>
                                    <option value="LinkedIn">{t('book_tour.linkedin')}</option>
                                    <option value="Bayut">{t('book_tour.bayut')}</option>
                                    <option value="Aqar">{t('book_tour.aqar')}</option>
                                    <option value="Referral">{t('book_tour.referral')}</option>
                                    <option value="Other">{t('book_tour.other')}</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="description">
                                <Form.Label>{t('book_tour.description')}</Form.Label>
                                <Form.Control as="textarea" rows={3} value={formState.description} name="description" onChange={handleInputChange} />
                            </Form.Group>
                            <Form.Group controlId="recaptcha" className="mt-3">
                                <ReCAPTCHA
                                    sitekey="6LfuedQkAAAAADr9jcezg632iLfHa4KJUf8vtQS2"
                                    onChange={handleRecaptchaChange}
                                />
                            </Form.Group>
                            <br />
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Form.Group controlId="disclaimerAccepted">
                                        <Form.Check type="checkbox" label={t('book_tour.disclaimer')} checked={formState.disclaimerAccepted} name="disclaimerAccepted" onChange={handleInputChange} required />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <ArrowLink href='' text={t('book_tour.submit_request')} onClick={handleSubmit} disabled={loading} className={loading ? 'disabled' : ''} />
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    {loading == true && <Spinner animation="border" variant="dark" size='sm' className={i18n.language === 'ar' ? 'float-left' : 'float-right'} />}
                                </Col>
                            </Row>

                            {msg && <Alert variant={msgType} className="mt-3">
                                <div dangerouslySetInnerHTML={{ __html: msg }} />
                            </Alert>
                            }

                        </Form>
                    </Col>
                </Row>
            )}
            <Footer />
        </>
    );
}


export default BookTour;
