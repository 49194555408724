import React, { useState, useRef, useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { useTranslation } from 'react-i18next';
import './Home.css';

import animatedLogo from '../../assets/Animated Logo.gif';
import { APP_ROUTES } from '../../const/const';
import ArrowLink from '../ArrowLink/ArrowLink';
import Project from '../Project/Project';
import RowProjects from '../RowProjects/RowProjects';
import { Projects } from '../../Projects/Main';
import { Projects_ar } from "../../Projects/Main";

import video from '../../assets/drfah-demo.mp4';
import videoPoster from '../../assets/main.png';
import AutoPlaySilentVideo from '../Video/Video';


function Home() {
  const HeroFramRef = useRef(null);
  const AboutUsFramRef = useRef(null);
  const RecentProjectsFramRef = useRef(null);
  const ProjectsFramRef = useRef(null);
  const serviceFramRef = useRef(null);

  const { t, i18n } = useTranslation();

  let ProjectsList = Projects;
  if (i18n.language === "ar") {
    ProjectsList = Projects_ar;
  }

  const overlayDelay = 1800;
  const [showOverlay, setShowOverlay] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowOverlay(false);
    }, overlayDelay);

    return () => clearTimeout(timeout);
  }, []);
  const randomNumber = Math.random();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={`overlay ${showOverlay ? 'visible' : ''}`}>
        {showOverlay && (
          <img src={animatedLogo + '?key=' + randomNumber} alt="Logo" />
        )}
      </div>
      <div className="scrolling-content">
        <Navbar transtion={true} overlayisShown={showOverlay} />
        <div className="nav-overlay" />
        <Fade delay={overlayDelay} triggerOnce={true}>
          <div ref={HeroFramRef} className="section hero-section">
            <div className="hero-overlay">
              <div className="hero-text">
                <div className="hero-title">{t('home.hero.title')}</div>
                <div className="hero-subtitle">{t('home.hero.subtitle')}</div>
              </div>
            </div>
            <AutoPlaySilentVideo video={video} className="video-container" poster={videoPoster} />
          </div>
        </Fade >

        <div ref={AboutUsFramRef} className="section text-section">
          <Fade>
            <div className="section-content">
              <div className="section-text">{t('home.about_us.text')}</div>
              <ArrowLink href={APP_ROUTES.ABOUT} text={t('home.about_us.link')} />
            </div>
          </Fade>
        </div>


        <div ref={RecentProjectsFramRef} className="section projects-section">
          <div className="section-content" style={{ width: '100%' }}>
            <Fade>

              <Project project={ProjectsList[0]} recent={true} readMore={true} />
            </Fade>
          </div>
        </div>


        <Fade>
          <div ref={ProjectsFramRef} className="section moreprojects-section">

            <RowProjects project1={ProjectsList[1]} project2={ProjectsList[2]} projectsLink={APP_ROUTES.PROJECTS} />

          </div>
        </Fade>

        <div ref={serviceFramRef} className="section text-section">
          <Fade>
            <div className="section-content">
              <div className="section-text">{t('home.services.text')}</div>
              <ArrowLink href={APP_ROUTES.SERVICES} text={t('home.services.link')} />
            </div>
          </Fade>
        </div>

        <Footer />
      </div >
    </>
  );
}

export default Home;