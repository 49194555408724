import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES } from '../../const/const';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

import logo from '../../assets/logo-menu.png';

import './navbar.css';

const CustomNavbar = ({ t, transtion = false, overlayisShown = false }) => {
    const navbarClasses = transtion ? (overlayisShown ? 'navbar-transition-hidden' : 'navbar-transition') : '';
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    return (
        <Navbar bg="white" variant="light" expand="lg" sticky="top" className={navbarClasses} dir='ltr' >
            <Navbar.Brand href={APP_ROUTES.HOME}>
                <img src={logo} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav">
                <span> </span>
                <span> </span>
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ml-lg-auto" dir={currentLanguage === 'ar' ? 'rtl' : 'ltr'}>
                    <Nav.Link href={APP_ROUTES.ABOUT} className='nav-link-no-arrow'>{t('navbar.about')}</Nav.Link>
                    <Nav.Link href={APP_ROUTES.SERVICES} className='nav-link-no-arrow'>{t('navbar.services')}</Nav.Link>
                    <Nav.Link href={APP_ROUTES.PROJECTS} className='nav-link-no-arrow'>{t('navbar.projects')}</Nav.Link>
                    {/* <Nav.Link href="#" className='nav-link-no-arrow'>{t('navbar.residents_portal')}</Nav.Link> */}
                    <Nav.Link href={APP_ROUTES.CONTACT} className='nav-link-no-arrow'>{t('navbar.contact')}</Nav.Link>

                    <Nav.Link className='nav-link-no-arrow'><LanguageSelector type='individual' /></Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar >
    );
};

export default withTranslation()(CustomNavbar);