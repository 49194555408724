import './App.css';
import { I18nextProvider, withTranslation } from 'react-i18next';
import i18n from './i18n';
import { Routes, Route } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

import { APP_ROUTES } from './const/const';
import Home from './components/Home/Home';
import ProjectsPage from './components/ProjectsPage/Projects';
import AboutUs from './components/AboutUsPage/AboutUs';
import Services from './components/ServicesPage/Services';
import './assets/Stolzl-Book.ttf';
import ContactUs from './components/ContactUsPage/ContactUs';
import ProjectDetails from './components/ProjectDetailsPage/Main';
import Gallery from './components/GalleryPage/Gallery';
import BookTour from './components/BookTourPage/BookTour';
import FloorPlans from './components/FloorPlansPage/FloorPlans';
import './assets/TheSans-Plain-1.ttf';

function App() {
  return (
    <I18nextProvider i18n={i18n}>

      <div className="App container-lg">
        <ParallaxProvider>
          <Routes>
            <Route path={APP_ROUTES.HOME} element={<Home />} />
            <Route path={APP_ROUTES.PROJECTS} element={<ProjectsPage />} />
            <Route path={APP_ROUTES.ABOUT} element={<AboutUs />} />
            <Route path={APP_ROUTES.SERVICES} element={<Services />} />
            <Route path={APP_ROUTES.CONTACT} element={<ContactUs />} />
            <Route path={APP_ROUTES.PROJECT_DETAILS} element={<ProjectDetails />} />
            <Route path={APP_ROUTES.PROJECT_GALLERY} element={<Gallery />} />
            <Route path={APP_ROUTES.BOOK_A_TOUR} element={<BookTour />} />
            <Route path={APP_ROUTES.PROJECT_FLOOR_PLANS} element={<FloorPlans />} />

            {/* Catch-all route for the home page */}
            <Route path="*" element={<Home />} />
          </Routes>
        </ParallaxProvider>
      </div>

    </I18nextProvider>
  );
}

export default withTranslation()(App);
